import React from "react"
import { SectionProps } from "../../types"
import { Translate } from "../../components/translations"
import "./styles.scss"
import classNames from 'classnames';

const Section = (props: SectionProps) => {
  const generateClassName = (props: SectionProps): string => {
    if (props.grey) {
      return "grey_section_area"
    } else if (props.primary) {
      return "blue_section_area"
    } else if (props.light) {
      return "light_section_area"
    } else if (props.lightGrey) {
      return 'light_grey_section_area'
    } else {
      return "light_section_area"
    }
  }

  const className = generateClassName(props)

  return (
    <div
      style={props.style && props.style}
      className={`${
        props.className ? `${props.className} ${className}` : className
      } sec_pad`}
    >
      <div className="container">
        {props.title && (
          <div
            className="sec_title text-center mb_70 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <h2 className="f_p l_height48 f_600 t_color" style={{color: '#263b5e'}}>
              {typeof props.title === "string" ? (
                <Translate name={props.title} />
              ) : (
                props.title
              )}
            </h2>
            {props.subtitle && (
              <p className="f_300 f_size_16 mb-0">
                {typeof props.subtitle === "string" ? (
                  <Translate name={props.subtitle} />
                ) : (
                  props.subtitle
                )}
              </p>
            )}
          </div>
        )}
        <div className={classNames("row", {'justify-content-center': props.about})}>{props.children}</div>
      </div>
    </div>
  )
}

export default Section
