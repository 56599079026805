import React from 'react';
import { Link } from 'gatsby';
import { faWordpress } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import slack from '../../img/integrations/slack.svg';
import trello from '../../img/integrations/trello.svg';
import telegram from '../../img/integrations/telegram.svg';
import google from '../../img/integrations/google.svg';
import chrome from '../../img/integrations/chrome.svg';
import googleSheets from '../../img/integrations/google-sheets.svg';
import webhook from '../../img/integrations/webhooks.svg';
import pabbly from '../../img/integrations/pabbly.png';
import discord from '../../img/integrations/discord.svg';
import zapier from '../../img/zapier.png';
import './styles.scss';

const PageIntegrations = () => {
  const points = [
    {
      name: 'Telegram',
      logo: telegram,
      linkTo: '/telegrambot',
    },
    {
      name: 'Chrome',
      logo: chrome,
    },
    {
      name: 'Slack',
      logo: slack,
      linkTo: '/slackbot',
    },
    {
      name: 'WP',
      logo: faWordpress,
      linkTo: '/broken-links-repair',
    },
    {
      name: 'Gmail',
      logo: google,
    },
    {
      name: 'Trello',
      logo: trello,
      linkTo: '/trellobot',
    },
    {
      name: 'Google Sheets',
      logo: googleSheets,
    },
    {
      name: 'Zapier',
      logo: zapier,
      linkTo: '/zapier',
    },
    {
      name: 'Pabbly Connect',
      logo: pabbly,
      linkTo: '/pabbly-connect',
    },
    {
      name: 'Webhook',
      logo: webhook,
      linkTo: '/webhook',
    },
    {
      name: 'Discord',
      logo: discord,
      linkTo: '/discord',
    },
  ];

  return (
    <div className="pageIntegrations text-center mb-30">
      <div className="integration-mobile row m-0">
        {points.map((item) => {
          return (
            <div key={item.name} className="col-12 col-sm-4 col-lg-2">
              <div className="promo_item">
                {item.linkTo ? (
                  <Link to={item.linkTo || ''}>
                    {item.name === 'WP' ? (
                      <FontAwesomeIcon
                        icon={item.logo}
                        size="3x"
                        color="#26749c"
                      />
                    ) : (
                      <img
                        src={item.logo}
                        alt={item.name}
                        width={44}
                        height={44}
                      />
                    )}
                    <p>{item.name}</p>
                  </Link>
                ) : (
                  <>
                    {item.name === 'WP' ? (
                      <FontAwesomeIcon
                        icon={item.logo}
                        size="3x"
                        color="#26749c"
                      />
                    ) : (
                      <img
                        src={item.logo}
                        alt={item.name}
                        width={44}
                        height={44}
                      />
                    )}
                    <p>{item.name}</p>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PageIntegrations;
